<template>
  <div class="container bg-light px-5">
    <h1>Termine</h1>
    <h2>Unsere Kampagnentermine 2025 - 2026</h2>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <td>28.06.2025</td>
                <td>Öffentliches Sommerfest für Freunde und Vereine</td>
              </tr>
              <tr>
                <td>18.10.2025</td>
                <td>Inmarschsetzung</td>
              </tr>
              <tr>
                <td>15.11.2025</td>
                <td>Inthronisation</td>
              </tr>
              <tr>
                <td>12 – 14.12.2025</td>
                <td>Weihnachtsmarkt</td>
              </tr>
              <tr>
                <td>11.01.2026</td>
                <td>Stadtempfang + Rittercafé</td>
              </tr>
              <tr>
                <td>17.01.2026</td>
                <td>Spektakel</td>
              </tr>
              <tr>
                <td>24.01.2026</td>
                <td>Prunksitzung</td>
              </tr>
              <tr>
                <td>14.02.2026</td>
                <td>Fastnachtssamstag</td>
              </tr>
              <tr>
                <td>15.02.2026</td>
                <td>Umzug Viernheim</td>
              </tr>
              <tr>
                <td>17.02.2026</td>
                <td>Kampagnenabschluss</td>
              </tr>
              <tr>
                <td>18.02.2026</td>
                <td>Heringsessen</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12">
        <h3>Der Terminflyer der Großen 3 Viernheim</h3>
        <img
          src="/images/termine-2025-2026_500x694.jpg"
          alt="Terminflyer der Großen 3 Viernheim, der die oben genannten Termine widerspiegelt"
          class="img-fluid mx-auto d-block"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Kampagnentermine - Übersicht unserer Veranstaltungen",
      og: {
        title: "Kampagnentermine - Übersicht unserer Veranstaltungen",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
