<template>
  <div class="container bg-light px-5">
    <h1>Aktuelles</h1>

    <h2>Unsere Kampagnentermine 2025 - 2026</h2>
    <p>
      Hier geht es zu unseren
      <router-link to="/termine">
        Terminen für die Kampagne 2025 / 2026
      </router-link>
    </p>

    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td>28.06.2025</td>
            <td>Öffentliches Sommerfest für Freunde und Vereine</td>
          </tr>
          <tr>
            <td>18.10.2025</td>
            <td>Inmarschsetzung</td>
          </tr>
          <tr>
            <td>15.11.2025</td>
            <td>Inthronisation</td>
          </tr>
          <tr>
            <td>12 – 14.12.2025</td>
            <td>Weihnachtsmarkt</td>
          </tr>
          <tr>
            <td>11.01.2026</td>
            <td>Stadtempfang + Rittercafé</td>
          </tr>
          <tr>
            <td>17.01.2026</td>
            <td>Spektakel</td>
          </tr>
          <tr>
            <td>24.01.2026</td>
            <td>Prunksitzung</td>
          </tr>
          <tr>
            <td>14.02.2026</td>
            <td>Fastnachtssamstag</td>
          </tr>
          <tr>
            <td>15.02.2026</td>
            <td>Umzug Viernheim</td>
          </tr>
          <tr>
            <td>17.02.2026</td>
            <td>Kampagnenabschluss</td>
          </tr>
          <tr>
            <td>18.02.2026</td>
            <td>Heringsessen</td>
          </tr>
        </tbody>
      </table>

      <h3>Der Terminflyer der Großen 3 Viernheim</h3>
      <img
        src="/images/termine-2025-2026_500x694.jpg"
        alt="Terminflyer der Großen 3 Viernheim, der die oben genannten Termine widerspiegelt"
        class="img-fluid mx-auto d-block"
      />
    </div>

    <div class="accordion pt-5 mt-5" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Kampagne 2024 / 2025
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <h2>Stadtempfang der Großen 3 im Ratssaal um 13:11 Uhr</h2>
            <h3>
              Prinzessin Celina I. und ihr 11er-Rat freuen sich auf viele Gäste
              am 12.01.2025 um 13:11 Uhr im Alten Rathaus
            </h3>
            <h4>Anschließend Rittercafé in der Kulturscheune</h4>
            <div class="row">
              <div class="col-12 col-xl-8">
                <p>
                  Am kommenden Sonntag, dem 12. Januar 2025, ist es wieder so
                  weit: Der traditionelle
                  <strong>Stadtempfang der „Großen 3“</strong> findet im Alten
                  Rathaus statt. Prinzessin <strong>Celina I.</strong> und ihr
                  Elferrat freuen sich darauf, zahlreiche Gäste um
                  <strong>13:11</strong> Uhr im Ratssaal willkommen zu heißen.
                </p>
                <p>
                  Auch in diesem Jahr hat der Elferrat keine Mühen gescheut, um
                  die Ankunft ihrer Prinzessin spektakulär zu gestalten. Nach
                  den kreativen Auftritten der letzten Jahre – sei es mit
                  Tretrollern, Stretch-Limousinen oder Rettungswagen – wird auch
                  2025 wieder eine Überraschung für Aufsehen sorgen.
                </p>
                <p>
                  Die Begrüßung vor dem Rathaus erfolgt durch den
                  <strong>1. Stadtrat Jörg Scheidel</strong> unter dem Klang von
                  Salutschüssen des Schützenvereins Viernheim. Musikalisch
                  begleitet wird das närrische Treiben von
                  <strong>„Musik hoch drei“,</strong>
                  die für die richtige Fastnachtsstimmung sorgen.
                </p>
                <p>
                  Im Anschluss an die Begrüßung beginnt das Programm im
                  Ratssaal. Dort wartet eine besondere Aufgabe auf den 1.
                  Stadtrat, der sich wie jedes Jahr den begehrten Jahresorden
                  erst verdienen muss. Gleichzeitig wird die Stadtspitze ihre
                  eigene Herausforderung für die Prinzessin und ihren Elferrat
                  vorbereiten – was genau, bleibt natürlich eine Überraschung.
                  Ob tänzerisches Talent, musikalische Einlagen oder sportliche
                  Herausforderungen – die Gäste dürfen gespannt sein!
                </p>
                <p>
                  Zum Abschluss des Stadtempfangs wird Prinzessin
                  <strong>Celina I.</strong> ihre
                  <strong>11 närrischen Paragrafen</strong> verkünden und damit
                  die Veranstaltung offiziell abrunden.
                </p>
              </div>
              <div class="col-12 col-xl-4">
                <img
                  src="/images/prinzessin-celina-der-grossen-drei-viernheim-rotes-kleid.jpg"
                  alt="Bild der Prinzessin 2024 / 2025 der Großen 3 Viernheim, Celina I."
                  class="img-fluid"
                />
                <small>Prinzessin Celina I.</small>
              </div>
            </div>
            <p class="fw-bold mb-0">Einladung an alle Viernheimer</p>
            <p>
              Alle Viernheimer Bürgerinnen und Bürger sind herzlich eingeladen,
              an diesem besonderen Ereignis teilzunehmen und gemeinsam mit der
              Prinzessin und ihrem Gefolge einen unvergesslichen Nachmittag zu
              erleben.
            </p>
            <p class="fw-bold mb-0">
              Anschließend: Rittercafé in der Kulturscheune
            </p>
            <p>
              Im Anschluss an den Stadtempfang laden die Ritter und Burgfrauen
              der „Großen 3“ zum traditionellen <strong>Rittercafé</strong> ein.
              Dieses findet in der Kulturscheune am
              <strong>Satonevriplatz</strong> statt. Neben Kaffee und Kuchen
              werden auch kalte Getränke angeboten. Für die kleinen Gäste gibt
              es ein Kinderprogramm und die Möglichkeit, sich bei einer
              Autogrammstunde mit Prinzessin Celina I. ein persönliches Andenken
              zu sichern.
            </p>
            <p>
              Das gesamte Team der „Großen 3“ freut sich auf zahlreiche Gäste
              und einen fröhlichen, unvergesslichen Nachmittag!
            </p>

            <h2>Inthronisation</h2>
            <p>
              Am 16.11.2024 war es wieder soweit! Wir haben unsere neue
              Prinzessin Celina I. feierlich inthronisiert.
            </p>
            <p>
              Zuerst entführte unser Elferrat das Publikum aber mit seinem
              Auftritt in die Welt von ABBA, bevor die Red Maries nochmal
              richtig für Stimmung sorgten.
            </p>
            <p class="pb-5">
              Nach dem Einzug unseres Elferrats durfte unsere scheidende
              Prinzessin Lenia I. noch ein paar Worte an das Publikum richten.
              Dann war es aber endlich soweit, unter großem Applaus zog unsere
              neue Prinzessin Celina I. in die Narhalla ein. Nun stand der
              Prinzessinnen-Test auf dem Programm, denn jede Prinzessin muss
              sich zuerst als würdig erweisen. Natürlich wurde diese Aufgabe mit
              Bravour gemeistert. Nachdem Celina von ihrer Vorgängerin gekrönt
              worden war, durfte sie ihre ersten Worte an das Publikum richten.
              Nach den ersten Ordensverleihungen an die Eltern der Prinzessin
              und den Elferrat wurde nach dem offiziellen Teil bis tief in die
              Nacht gefeiert.
            </p>

            <h2>
              Celina I.: Die neue Fastnachtsprinzessin der „Großen 3“ steht in
              den Startlöchern
            </h2>
            <div class="row">
              <div class="col-12 col-xl-6">
                <p>
                  Mit einem strahlenden Lächeln und jeder Menge Vorfreude blickt
                  Celina Winkel, die 24-jährige Fastnachtsprinzessin der „Großen
                  3“, in die kommende Kampagne. Als „Celina I.“ wird sie in der
                  fünften Jahreszeit das närrische Zepter schwingen und die
                  Herzen der Narren erobern. Schon jetzt laufen die
                  Vorbereitungen auf Hochtouren, denn das Programm für die
                  kommenden Monate ist prall gefüllt. Doch für Celina I., die
                  Fastnacht von klein auf lebt, ist das mehr Vergnügen als
                  Pflicht.
                </p>
                <h3>Fastnacht im Blut</h3>
                <p>
                  Celina I. stammt aus einer echten Fastnachtsfamilie. Schon
                  ihre Mutter Andrea stand als Prinzessin in der Doppelkampagne
                  1990/1992 auf der Bühne, und auch ihr Vater Stefan war im
                  Verein als Elferrat aktiv. Kein Wunder, dass Celina und ihr
                  Bruder Tim, der mit 21 Jahren bereits selbst im Elferrat der
                  „Großen 3“ tätig war, von Kindesbeinen an Teil des närrischen
                  Treibens sind. „Fastnacht ist bei uns zu Hause einfach
                  Tradition. Es gehört zur Familie wie Weihnachten und Ostern“,
                  erzählt Celina mit einem Lächeln. Bereits als Kind war sie
                  tief in das Fastnachtstreiben eingebunden – es gibt sogar
                  Kinderbilder von ihr, auf denen sie als kleines Mädchen auf
                  dem Arm von Fastnachtsprinzessinnen zu sehen ist. Ihre eigene
                  Fastnachtskarriere begann früh: 2010/2011 war sie
                  Gründungsmitglied der „Mini Maries“, der Nachwuchsgarde des
                  Vereins. Seit der Kampagne 2015/2016 tanzt sie nun bei den
                  „Red Maries“, der Garde der „Großen 3“, und hat dort
                  inzwischen die Trainerrolle übernommen. In dieser Kampagne
                  muss sie das Tanzen zugunsten ihrer Prinzessinnenaufgaben
                  allerdings aussetzen – aber auf den Showtanz der Garde freut
                  sie sich trotzdem besonders. „Es wird ungewohnt sein, dieses
                  Jahr nicht mitzutanzen, aber ich werde jede Sekunde auf der
                  Bühne mitfiebern“, verspricht sie.
                </p>
              </div>
              <div class="col-12 col-xl-6">
                <img
                  src="/images/prinzessin-celina-der-grossen-drei-viernheim.jpg"
                  alt="Bild der Prinzessin 2024 / 2025 der Großen 3 Viernheim, Celina I."
                  class="img-fluid"
                />
                <small>Prinzessin Celina I.</small>
              </div>
            </div>

            <h3>
              Überraschung im Elternhaus: Der Moment, als Celina Prinzessin
              wurde
            </h3>
            <p>
              Die Ernennung zur Prinzessin kam für Celina durchaus überraschend.
              Eines Abends, als sie mit ihrem Freund Tim Peterl – der übrigens
              niemand Geringerer ist als der Präsident der „Großen 3“ – zum
              Essen bei ihren Eltern war, passierte es: „Wir waren eigentlich
              fertig, ich wollte mich umziehen, da riefen mich meine Eltern noch
              mal ins Wohnzimmer“, erinnert sich Celina. Dort warteten plötzlich
              die zwei Vizepräsidenten des Vereins, Moritz Gerner und Carlo
              Brandmüller, mit einem riesigen Blumenstrauß. „Ich war total
              überrascht, aber es dauerte nicht lange, bis ich ‚Ja‘ sagte. Ich
              musste wirklich nicht lange überlegen“, lacht Celina. Der Abend
              endete – wie könnte es anders sein – mit mehreren Gläsern Sekt und
              jeder Menge Vorfreude.
            </p>
            <h3>Soziale Arbeit und KjG – Das Leben außerhalb der Fastnacht</h3>
            <p>
              Neben der Fastnacht und ihrem Amt als Prinzessin ist Celina auch
              in der Jugendarbeit engagiert. Sie ist Mitglied der Katholischen
              Jungen Gemeinde (KjG) St. Michael, wo sie als Lagerleiterin das
              jährliche Kinderzeltlager organisiert. „Die Arbeit mit Kindern und
              Jugendlichen macht mir wahnsinnig viel Spaß. Es ist toll, ihnen
              etwas mit auf den Weg zu geben und Verantwortung zu übernehmen“,
              erzählt sie. Auch beruflich bleibt sie diesem Bereich treu: Nach
              ihrem Studium der Sozialen Arbeit in Darmstadt befindet sich
              Celina derzeit im Anerkennungsjahr bei der Ambulanten
              Familienhilfe.
            </p>
            <p>
              „Die Werte, die ich in der KjG gelernt habe, wie Zusammenhalt und
              Gemeinschaft, möchte ich auch in meine Zeit als Prinzessin
              einfließen lassen“, erklärt sie. Für Celina I. geht es nicht nur
              um Spaß und Feiern, sondern auch darum, Menschen zusammenzubringen
              und gemeinsam etwas zu bewegen.
            </p>
            <h3>
              Vorbereitung auf die Kampagne: Kleider, Orden und Sektproben
            </h3>
            <p>
              Kaum hatte Celina I. das Amt angenommen, begann auch schon die
              Planung der Kampagne. „Das Präsidium hat mir einen ganzen Ordner
              mit To-Dos überreicht“, erzählt sie schmunzelnd. Gemeinsam mit
              ihrer Mutter Andrea ging es ans Aussuchen der eleganten Kleider.
              Vier Stück in Blau, Lila, Bordeauxrot und Mint sind es am Ende
              geworden – jede für einen anderen Anlass. „Das Kleider aussuchen
              war eine der schönsten Vorbereitungen. Meine Mama hatte natürlich
              die besten Tipps“, erzählt sie.
            </p>
            <p>
              Auch das Design des Kampagnenordens lag in Celinas Händen. Der
              Red-Maries-Stiefel ist darauf zu sehen, womit sie Ihre Verbindung
              zur Garde verdeutlichen möchte. Für die Farbauswahl zeigt sich
              Bruder Tim verantwortlich, der als eingefleischter
              Waldhof-Mannheim-Fan das blau vorschlug.
            </p>
            <p>
              Auf dem diesjährigen Sektetikett sind sie und ihr Elferrat im Bett
              abgebildet, zusammen mit dem Slogan: „Let’s talk about Sekt“.
              Apropos Sekt: Die obligatorische Sektprobe durfte natürlich nicht
              fehlen. Es wird zwei Sorten geben – rosé und weiß. „Wer neugierig
              ist, wie der Sekt schmeckt, sollte sich unbedingt die
              Inthronisation am 16.11. anschauen“, verrät Celina mit einem
              Augenzwinkern.
            </p>

            <p>
              Ein wichtiger Termin war auch das Fotoshooting, das zusammen mit
              dem Präsidium in Ketsch stattfand. Dort entstanden die offiziellen
              Kampagnenbilder, die im Kampagnenheft zu sehen sein werden. Motto
              der Kampagne: „Tanzen, Lachen, Party machen“
            </p>
            <p>
              Natürlich durfte auch das Motto der Kampagne nicht fehlen. Dabei
              bekam Celina tatkräftige Unterstützung von ihrem Vater Stefan, der
              ihr mehrere Vorschläge in Reimform vorlegte. Am Ende entschied sie
              sich für: „Tanzen, Lachen, Party machen – Die Großen 3, die
              lassen´s krachen.“ „Das Motto beschreibt perfekt, worauf es mir in
              dieser Kampagne ankommt: Gemeinsam Spaß haben, die Fastnacht
              feiern und das Leben genießen“, so Celina.
            </p>
            <p>
              Auch bei den Reden für die Kampagne, etwa für das Kampagnenheft
              oder die Inthronisation, bekam sie Unterstützung – von ihren
              Eltern und natürlich von ihrem Freund Tim. „Er ist nicht nur mein
              größter Unterstützer, sondern auch der beste Ratgeber. Ich bin
              sehr dankbar, dass wir diese Kampagne zusammen gestalten dürfen“,
              erzählt sie.
            </p>
            <h3>
              Die Höhepunkte der Kampagne: Von der Inthronisation bis zur
              Prunksitzung
            </h3>
            <p>
              Celina I. erwartet in den nächsten Monaten eine aufregende Zeit,
              die mit der Inthronisation am 16. November 2024 in der Gaststätte
              des Schrebergartenvereins ihren Anfang nimmt. Ein besonderer
              Moment wird dabei der Prinzessinnentest sein, den sie bestehen
              muss, bevor sie offiziell die Zepter, die Krone und die Orden der
              Prinzessin erhält. „Ich bin gespannt, welche Aufgaben auf mich
              zukommen“, sagt sie lachend. Die Insignien werden ihr von ihrer
              Vorgängerin Lenia I. sowie den Rittern und Burgfrauen überreicht.
            </p>
            <p>
              Nach der Inthronisation folgen zahlreiche weitere Veranstaltungen,
              darunter der Stadtempfang am 12. Januar 2025 im Alten Rathaus und
              das Spektakel am 18. Januar 2025 im Vereinsheim des MGV 1846
              Viernheim. Der absolute Höhepunkt wird für Celina jedoch die
              Prunksitzung am 15. Februar 2025 im Bürgerhaus sein. „Darauf freue
              ich mich besonders. Die Stimmung bei den Prunksitzungen ist immer
              magisch“, schwärmt sie.
            </p>
            <p>
              Weitere Termine sind die Fastnachtssamstagsparty am 1. März 2025
              sowie die Straßenfastnacht in der Viernheimer Innenstadt am 2.
              März 2025. „Ich freue mich auf jede einzelne Veranstaltung, aber
              vor allem auf die Momente, in denen wir alle zusammen feiern
              können“, so Celina.
            </p>
            <p class="pb-5">
              Mit so viel Vorfreude, Engagement und Liebe zur Fastnacht kann die
              Kampagne der „Großen 3“ nur ein voller Erfolg werden. Celina I.
              steht bereit – und mit ihr eine Stadt, die sich auf eine bunte,
              fröhliche und unvergessliche Fastnachtszeit freut. Ahoi!
            </p>

            <h2>Die nächste Veranstaltung rückt näher</h2>
            <p>
              Am 16.11.2024 starten wir offiziell in die Kampagne mit der
              Inthronisation unserer neuen Prinzessin.
            </p>
            <p>
              Bereits ab dem 11.11.2024 könnt Ihr hier mehr über unsere neue
              Lieblichkeit erfahren
            </p>
            <p>
              Wir freuen uns sehr, mit euch die närrische Kampagne und viele
              freudige Stunden voll Jubel und Heiterkeit zu eröffnen. Save the
              date!
            </p>
            <p class="mb-0">
              <i class="fa-solid fa-calendar-days" /> 16.11.2024
            </p>
            <p class="mb-0"><i class="fa-solid fa-clock"></i> 19:11 Uhr</p>
            <p class="mb-0">
              <i class="fa-solid fa-location-dot"></i> Gaststätte
              Schrebergärtenverein Viernheim, Am Lampertheimer Weg 25
            </p>
            <p><i class="fa-solid fa-music"></i> Mit Musik von DJ Lohrbie</p>
            <p class="pb-5">
              <img
                src="/images/inthro-2024-1920x1080.jpg"
                class="img-fluid mx-auto"
                alt="Werbung für die Inthronisation 2024"
              />
            </p>

            <h2>Unsere Kampagnentermine 2024 - 2025</h2>
            <p>
              Hier geht es zu unseren
              <router-link to="/termine">
                Terminen für die Kampagne 2024 / 2025
              </router-link>
            </p>

            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>16.11.2024</td>
                    <td>Inthronisation</td>
                    <td>Gaststätte Schrebergärtenverein Viernheim</td>
                  </tr>
                  <tr>
                    <td>07.12.2024</td>
                    <td>Weihnachtsfeier</td>
                    <td>Pfadfinderheim</td>
                  </tr>
                  <tr>
                    <td>13 – 15.12.2024</td>
                    <td>Weihnachtsmarkt</td>
                    <td>Viernheimer Innenstadt</td>
                  </tr>
                  <tr>
                    <td>12.01.2025</td>
                    <td>Stadtempfang + Rittercafe</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>18.01.2025</td>
                    <td>Spektakel</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>15.02.2025</td>
                    <td>Prunksitzung</td>
                    <td>Bürgerhaus</td>
                  </tr>
                  <tr>
                    <td>01.03.2025</td>
                    <td>Fastnachtssamstag</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>02.03.2025</td>
                    <td>Straßenfastnacht Viernheim</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>04.03.2025</td>
                    <td>Umzug Lorsch</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>05.03.2025</td>
                    <td>Kampagnenabschluss</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Aktuelle Beiträge rund um die Kampagne",
      og: {
        title: "Aktuelle Beiträge rund um die Kampagne",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
